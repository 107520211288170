import React from 'react'
import { connect } from 'react-redux'
// selectors
import { getFilterAllSysparam } from '../../../redux/selectors/setting'
// components
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItemSysparam from './ListItemSysparam'


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))



function ListSysparam(props) {

    const { listOfAllSysparams = [], onItemClick } = props

    const classes = useStyles();

    return (
        <List className={classes.root}>
            {listOfAllSysparams.map(sys => {

                return (
                    <ListItemSysparam
                        key={sys.name}
                        sysparam={sys}
                        onItemClick={onItemClick}
                    />
                );
            })}
        </List>
    );
}


const mapStateToProps = (state) => ({
    listOfAllSysparams: getFilterAllSysparam(state)
})

export default connect(mapStateToProps, null)(ListSysparam)
