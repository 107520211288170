import React from 'react'
import './style.scss'
import LoginForm from './LoginForm'
import { Fade , Container} from '@material-ui/core'


const LoginPage = (props) => {

    const { isLogin } = props

    return (
        <Fade in={!isLogin} timeout={1000}>
            <Container >
                <div className="wrapper-form">
                    <LoginForm />
                </div>
            </Container>
        </Fade>
    )
}

export default LoginPage