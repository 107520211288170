import { createSelector } from 'reselect'


const isLogin = state => state.auth.login
export const getIsLogin = createSelector(isLogin, login => !!login)

// export const getIsLogin = () => {
//     // new Date("2019-10-06T16:18:40+03:00") > new Date().getTime()
//     const auth = JSON.parse(localStorage.getItem('auth'))
//     return auth && auth.token && (new Date(auth.expire) > new Date().getTime())
// }
