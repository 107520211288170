import { createSelector } from 'reselect'


const filterSearchState = state => state.filter.search


const settingState = state => state.setting
const allSysparamState = state => state.setting.allSysparam
const allSysparamOverrideState = state => state.setting.allSysparam.filter(sys => sys.accountId)

// export const getSysparamOverride = createSelector(
//     allSysparamOverrideState,
//     sysparms => sysparms
// )

export const getAllSysparam = createSelector(settingState, setting => setting.allSysparam)

export const getFilterSysOverride = createSelector(
    allSysparamOverrideState,
    filterSearchState,
    (sysparms, search) => sysparms.filter(sys => sys.name.includes(search))
)

export const getFilterAllSysparam = createSelector(
    allSysparamState,
    filterSearchState,
    (sysparms, search) => sysparms.filter(sys => sys.name.includes(search))
)

export const getUpdateSysStatus = createSelector(settingState, setting => setting.updateSysStatus)

