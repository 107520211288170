import React from 'react'
import AppBarSearch from './AppBarSearch'



export default (props) => {
    return (
        <AppBarSearch />
    )
}
