import React, { useEffect } from 'react'
import SettingContext from '../../../context/SettingContext'
import { connect } from 'react-redux'
// actions
import { fetchAllSysparam, updateSysParamOverride, addSysParamOverride, deleteSysParamOverride } from '../../../redux/actions/setting'
// selectors
import { getAllSysparam, getFilterSysOverride, getUpdateSysStatus } from '../../../redux/selectors/setting'
import SysparamOverride from './SysparamOverride'
// components
import PageContainer from '../../PageContainer'


const SettingPage = (props) => {

    const {
        fetchAllSysparam,
        allSysparam,
        filterSysOverride,
        addSysParamOverride,
        updateSysParamOverride,
        deleteSysParamOverride
    } = props

    useEffect(() => {
        if (allSysparam.length === 0)
            fetchAllSysparam()
    }, [])

    const actions = {
        updateSysParamOverride,
        addSysParamOverride,
        deleteSysParamOverride
    }

    const state = {
        updateSysStatus: props.updateSysStatus
    }

    return (
        <PageContainer>
            <SettingContext.Provider value={{ actions, state }}>
                <SysparamOverride
                    sysparamOverride={filterSysOverride}
                />
            </SettingContext.Provider>
        </PageContainer>
    )
}


const mapStateToProps = (state) => ({
    allSysparam: getAllSysparam(state),
    filterSysOverride: getFilterSysOverride(state),
    updateSysStatus: getUpdateSysStatus(state)
})

const mapDispatchToProps = {
    fetchAllSysparam,
    updateSysParamOverride,
    addSysParamOverride,
    deleteSysParamOverride
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage)
