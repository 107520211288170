import React from 'react'
import darkTheme from './theme/darkTheme'
import { ThemeProvider } from '@material-ui/styles';
// Components
import AppLayout from './appLayout'


function App() {
    return (
        <div className="App">
            <ThemeProvider theme={darkTheme}>
                <AppLayout />
            </ThemeProvider>
        </div>
    );
}

export default App;
