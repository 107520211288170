import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'


const colorsStack = [
    '#651fff',
    '#0100ca',
    '#c30000',
    '#ff7539',
    '#2196f3',
    '#e91e63',
    '#7b1fa2',
    '#26a69a',
    '#00e676',
    '#c6ff00',
    '#c43e00',
    '#ff9100',
    '#ffea00',
    '#ff1744',
]

const random = () => ~~(Math.random() * colorsStack.length)


const Logo = (props) => {
    
    const { variant, logoText } = props

    const LOGO_TEXT = logoText || "DB Helper Setup"

    const [colors, setColors] = useState(updateColors(LOGO_TEXT))


    let interval
    useEffect(() => {
        interval = setInterval(setRandomColors, 3000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    function setRandomColors() {
        setColors(updateColors(LOGO_TEXT))
    }

    function updateColors(text = "") {
        return [...text].map(() => colorsStack[random()])
        // return [
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // colorsStack[random()],
        // ]
    }

    function createLogo(text = "") {
        return [...text].map((t, i) => {
            return <Text color={colors[i]}><Typography display="inline" variant={ variant ? variant : "h1"}>{t}</Typography></Text>
        })
    }

    return (
        <Container>
            {createLogo(LOGO_TEXT)}
            {/* <Text color={colors[0]}><Typography display="inline" variant={ variant ? variant : "h1"}>S</Typography></Text>
            <Text color={colors[1]}><Typography display="inline" variant={ variant ? variant : "h1"}>y</Typography></Text>
            <Text color={colors[2]}><Typography display="inline" variant={ variant ? variant : "h1"}>s</Typography></Text>
            <Text color={colors[3]}><Typography display="inline" variant={ variant ? variant : "h1"}>p</Typography></Text>
            <Text color={colors[4]}><Typography display="inline" variant={ variant ? variant : "h1"}>a</Typography></Text>
            <Text color={colors[5]}><Typography display="inline" variant={ variant ? variant : "h1"}>r</Typography></Text>
            <Text color={colors[6]}><Typography display="inline" variant={ variant ? variant : "h1"}>a</Typography></Text>
            <Text color={colors[7]}><Typography display="inline" variant={ variant ? variant : "h1"}>m</Typography></Text> */}
        </Container>
    )
}

export default Logo

const Container = styled.div`
    display: ${p => p.display || 'unset'}
`
//display: flex;
// font-size: xx-large;
const Text = styled.span`
    color: ${p => p.color};
    transition: all 2s
`
