import * as types from '../actionTypes'
import { fromJS } from 'immutable'


const initialState = {
    allSysparam: [],
    updateSysStatus: {},
}

export const setting = (state = initialState, { type, payload }) => {
  	    switch (type) {
            case types.FETCH_ALL_SYSPARAM_SUCCESS: {
                return { ...state, allSysparam: payload }
            }

            case types.FETCH_SYSPARAM_OVERRIDE_SUCCESS: {
                return { ...state, sysparamOverride: payload }
            }

            case types.UPDATE_SYSPARAM_OVERRIDE_START: {
                const { updateSysStatus } = state
                updateSysStatus[payload.name] = 'progress'
                return { ...state, updateSysStatus }
            }

            case types.UPDATE_SYSPARAM_OVERRIDE_SUCCESS: {
                // payload: { accountId, name, value1, value2, text }
                const { allSysparam, updateSysStatus } = state
                const sysIndex = allSysparam.findIndex(sys => sys.name === payload.name)
                if (sysIndex === -1) return state
                allSysparam[sysIndex] = { ...allSysparam[sysIndex], ...payload }

                updateSysStatus[payload.name] = ''
                return { ...state, allSysparam, updateSysStatus }
            }

            case types.UPDATE_SYSPARAM_OVERRIDE_FAILURE: {
                const { updateSysStatus } = state
                updateSysStatus[payload.name] = 'failed'
                return { ...state, updateSysStatus }
            }

            case types.ADD_SYSPARAM_OVERRIDE_SUCCESS: {
                const { name, accountId } = payload
                const allSysparam = [...state.allSysparam]
                const res = allSysparam.map(sys => {
                    if (sys.name === name) {
                        return { ...sys, accountId }
                    }
                    return sys
                })
                return { ...state, allSysparam: res }
            } 

            case types.ADD_SYSPARAM_OVERRIDE_FAILURE: {
                // TODO ...
                return state
            } 

            case types.DELETE_SYSPARAM_OVERRIDE_SUCCESS: {
                const { name, accountId } = payload
                const allSysparam = [...state.allSysparam]
                const res = allSysparam.map(sys => {
                    if (sys.name === name) {
                        return { ...sys, accountId: undefined }
                    }
                    return sys
                })
                return { ...state, allSysparam: res }
            }  

            case types.DELETE_SYSPARAM_OVERRIDE_FAILURE: {
                // TODO ...
                return state
            }  
  		  default:
  	    	  return state
  	}
}

export default setting
