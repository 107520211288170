
const urlQueryToObject = (query) => {
    // query = ?resourceType=2222&resourceId=1111&token=wwafsgsge
    const param = {}
    const queryStr = query.slice(1) // remove the ? from the string
    const splitQuery = queryStr.split("&")
    for (let i = 0; i < splitQuery.length; i++) {
        const q = splitQuery[i];

        const key = q.substring(0, q.indexOf("="));
        const value = q.substring(q.indexOf("=") + 1);

        param[key] = value
    }
    return param
}

const b64DecodeUnicode = (str) => decodeURIComponent(Array.prototype.map.call(atob(str), (c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
const parseJwt = (token) => token && JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));

export {
    urlQueryToObject,
    parseJwt
}
