import * as types from '../actionTypes'


export const setSearch = (search) => (dispatch) => {
    dispatch({ type: types.SET_SEARCH, payload: search })
}

export const clearSearch = () => (dispatch) => {
    dispatch({ type: types.CLEAR_SEARCH })
}
