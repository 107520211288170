import axios from 'axios'
import store from '../redux/store'
import { LOG_OUT } from '../redux/actionTypes'


export function getAxios(token) {
    const auth = JSON.parse(localStorage.getItem('auth'))
    if (auth && new Date(auth.expire) > new Date().getTime()) {
        const authorization = `Bearer ${token || auth.token}`
        axios.defaults.headers.common.Authorization = authorization
    } else {
        store.dispatch({ type: LOG_OUT })
    }
    return axios
}
