import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getIsLogin } from '../redux/selectors/auth'
import { pages } from '../constants'
// components
import LoginPage from '../components/pages/login'
import AppBar from '../components/widgets/appBar_search'
import AppMenu from '../components/widgets/app_menu'
import SettingPage from '../components/pages/setting'
import AllSysparams from '../components/pages/allSysaram'


const AppLayout = (props) => {

    const { isLogin } = props

    function getPage(pageName) {
        if (!isLogin) return <Redirect to={pages.LOGIN} />
        if (!pageName) return <Redirect to={pages.HOME} />

        switch (pageName) {
            case pages.LOGIN:
                return <LoginPage isLogin={isLogin}/>
            case pages.HOME:
                return <div style={{ background: '#df3432', height: 1800 }}>home page</div>
            case pages.SETTING:
                return <SettingPage />
            case pages.ALL_SYSPARAMS:
                return <AllSysparams />
            default:
                break;
        }
    }

    return (
        <BrowserRouter>
            {isLogin && <AppBar />}
            {isLogin && <AppMenu />}
            <Switch>
                <Route exact path={`/${pages.LOGIN}`}>
                    <LoginPage isLogin={isLogin}/>
                </Route>
                <Route exact path={`/${pages.HOME}`}>
                    {getPage('home')}
                </Route>
                <Route exact path={`/${pages.SETTING}`}>
                    {getPage('setting')}
                </Route>
                <Route exact path={`/${pages.ALL_SYSPARAMS}`}>
                    {getPage('all-sysparams')}
                </Route>
                <Route>
                    {getPage()}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => ({
    isLogin: getIsLogin(state)
})

export default connect(mapStateToProps, null)(AppLayout)