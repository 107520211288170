import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Fade } from '@material-ui/core'


function HideOnScroll(props) {
    const { children } = props
    const trigger = useScrollTrigger()
    const maxHeight600 = useMediaQuery('(max-height:600px)')

    return (
        <Fade in={!maxHeight600 ? true : !trigger} timeout={300}>
            {children}
        </Fade>
    );
}


export default HideOnScroll
