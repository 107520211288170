/** @jsx jsx */
import { useState } from 'react'
import { jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { signIn } from '../../../redux/actions/auth'
// components
import { TextField, Button, Container } from '@material-ui/core';
import Logo from '../../Logo'


const LoginForm = (props) => {

    const [values, setValues] = useState({})

    function handleChange({ target }) {
        const { value, name } = target
        setValues({ ...values, [name]: value })
    }

    function handleSubmit() {
        console.log(values)
        props.signIn(values)
    }

    return (
        <Container
            maxWidth="sm"
        >
            <Logo />

            <TextField
                onChange={handleChange}
                name="userName"
                label="Pointer user name"
                margin="normal"
                variant="outlined"
                fullWidth
                autoFocus
                autoComplete=""
            />
            <TextField
                onChange={handleChange}
                name="password"
                label="Password"
                margin="normal"
                variant="outlined"
                fullWidth
                type="password"
                autoComplete=""
            />

            <div className="buttons-wrapper">
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                    color="secondary"
                    >
                    Go
                </Button>
            </div>
        </Container>
    );
}

const mapDispatchToProps = {
    signIn
}

export default connect(null, mapDispatchToProps)(LoginForm)
