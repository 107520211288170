import React, { useState } from 'react'
import styled from '@emotion/styled'
// component
import CardSysOverride from './CardSysOverride'



const SysparamOverride = (props) => {
    const { sysparamOverride } = props
    const [selecteItemId, setSelectedItemId] = useState()

    function onExpansionPanelClick(id) {
        setSelectedItemId(id !== selecteItemId ? id : undefined)
    }

    function renderList() {
        const list = sysparamOverride.map(sys => (
            <CardSysOverride 
                key={sys.id} 
                sysparam={sys} 
                onExpansionPanelClick={onExpansionPanelClick}
                selected={selecteItemId === sys.id}
            />
        ))
        return list
    }

    return (
        <Container>
            {renderList()}
        </Container>
    )
}


export default SysparamOverride


const Container = styled.div`
    margin: 80px auto;
    padding: 0 24px;
    max-width: 800px;
    @media (max-width: 600px) {
        margin: 80px auto;
        padding: 0 16px;
        max-width: unset;
    }
    @media (max-width: 400px) {
        margin: 80px auto;
        padding: 0 6px;
        max-width: unset;
    }
`
