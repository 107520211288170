import * as types from '../actionTypes'
// import { fromJS } from 'immutable'


const initialState = {
    search: '',
    result: [],
}

export const filter = (state = initialState, { type, payload }) => {
  	    switch (type) {
            case types.SET_SEARCH: {
                return { ...state, search: payload }
            }
            case types.CLEAR_SEARCH: {
                return { ...state, search: '' }
            } 
  		  default:
  	    	  return state
  	}
}

export default filter
