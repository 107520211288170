import colors from './colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import { green, orange } from '@material-ui/core/colors';


const darkTheme = createMuiTheme({
    palette: {
        primary: {
            light: colors.BLUE_GREY_LIGHT,
            main: colors.BLUE_GREY,
            dark: colors.BLUE_GREY_DARK,
            contrastText: colors.WHITE,
        },
        secondary: {
            light: colors.PINK_LIGHT,
            main: colors.PINK,
            dark: colors.PINK_DARK,
            contrastText: colors.WHITE,
        },
    },
    hover: {
        background: colors.GREY_LIGHT
    }
})

export default responsiveFontSizes(darkTheme)
