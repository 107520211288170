import configApi from '../../config'
import { getAxios } from '../../utils/myAxios'
import * as types from '../actionTypes'
import { parseJwt } from '../../utils/helperFunc'


export const signIn = ({ userName, password }) => (dispatch) => {
    dispatch({ type: types.LOGIN_START })
    getAxios().post(configApi.URL.SIGNIN, {
        userName, 
        password
    })
    .then(({ data }) => {
        const jwt = parseJwt(data.token)
        localStorage.setItem('auth', JSON.stringify({
            ...data, userName, accountId: jwt.AccountId, login: true 
        }))
        getAxios(data.token)
        dispatch({
            type: types.LOGIN_SUCCESS,
            payload: { ...data, userName, accountId: jwt.AccountId, login: true }
        })
    })
    .catch(({ message }) => {
        dispatch({
            type: types.LOGIN_FAILURE,
            payload: { message, login: false }
        })
    })
}

export const logOut = () => (dispatch) => {
    dispatch({ type: types.LOG_OUT })
}
