import * as types from '../actionTypes'


export const openAppMenu = (open) => (dispatch) => {
    dispatch({
        type: open ? types.OPEN_APP_MENU : types.CLOSE_APP_MENU
    })
}

export const changePage = (page) => (dispatch) => {
    dispatch({
        type: types.CHANGE_PAGE,
        payload: page
    })
}