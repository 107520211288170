import * as types from '../actionTypes'
// import { fromJS } from 'immutable'

const getAuthData = () => JSON.parse(localStorage.getItem('auth')) || {}
const deleteAuthData = () => localStorage.removeItem('auth')

const initialState = {
    login: false,
    ...getAuthData()
}

export const auth = (state = initialState, { type, payload }) => {
  	    switch (type) {
            case types.LOGIN_SUCCESS: {
                return { ...state, ...payload }
            }
            case types.LOGIN_FAILURE: {
                return { ...payload }
            }
            case types.LOG_OUT: {
                // TODO reset redux state
                deleteAuthData()
                return { login: false }
            }  
  		  default:
  	    	  return state
  	}
}

export default auth
