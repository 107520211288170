import React, { useState, useContext } from 'react';
import SettingContext from '../../../context/SettingContext'
// component
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import TextField from '@material-ui/core/TextField';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Fade, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: '5px 0',
    },
    summary: {
        // '&:hover': {
        //     background: theme.hover.background,
        // },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        // alignItems: 'center',
        // justifyContent: 'space-around',
        alignItems: 'inherit',
        justifyContent: 'flex-start',
        flexWrap: "wrap-reverse",
    },
    summaryContent: {
        flexDirection: 'column',
        alignItems: 'start',
    },
    column: {
        // flexBasis: '33.33%',
        margin: '0 4px',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    progress: {
        flex: '1 1 50%'
    }
}));



// The component
function CardSysOverride(props) {

    const classes = useStyles();
    const {
        sysparam,
        onExpansionPanelClick,
        selected
    } = props

    const [values, setValues] = useState({
        value1: sysparam.value1,
        value2: sysparam.value2,
        text: sysparam.text,
    })
    console.log(values)
    const [raised, setRaised] = useState(false)

    const { actions, state } = useContext(SettingContext)

    function handleChange(e) {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    function handleSaveClick() {
        const data = { ...sysparam, ...values }
        console.log(data)
        actions.updateSysParamOverride(data)
    }

    function handleDeleteItem() {
        console.log(sysparam)
        actions.deleteSysParamOverride(sysparam)
    }

    function handleResetClick() {
        setValues({
            value1: sysparam.value1,
            value2: sysparam.value2,
            text: sysparam.text,
        })
    }

    function handleExpansionPanelClick(e) {
        const { id } = e.currentTarget
        if (onExpansionPanelClick)
            onExpansionPanelClick(id)
    }

    function onMouseOver() {
        setRaised(true)
    }

    function onMouseOut() {
        setRaised(false)
    }

    function isBeenChanged() {
        for (let [key, value] of Object.entries(values)) {
            if (sysparam[key] != value) {
                return true
            }
        }
        return false
    }

    function sysFieldValue(key) {
        let textField = null

        if (key === 'text') {
            textField = (
                <div className={classes.column} key={key}>
                    <TextField
                        name={key}
                        id="outlined-multiline-static"
                        label={key}
                        onChange={handleChange}
                        multiline
                        // rows="4"
                        value={values[key]}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
            )
        } else if (key === 'value1' || key === 'value2') {
            textField = (
                <div className={classes.column} key={key}>
                    <TextField
                        name={key}
                        id="outlined-number"
                        label={key}
                        value={values[key]}
                        onChange={handleChange}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
            )
        }
        return textField
    }

    function renderSysparamValues(sysparam) {
        let sysparamFields = []
        for (let [key, value] of Object.entries(sysparam)) {
            if (value !== undefined)
                sysparamFields.push(sysFieldValue(key))
        }
        return sysparamFields
    }

    const renderProgress = () => (
        <Fade
            in={state.updateSysStatus[sysparam.name] === 'progress'}
            style={{
                transitionDelay: state.updateSysStatus === 'progress' ? '800ms' : '0ms',
            }}
            unmountOnExit
            timeout={{ enter: 0, exit: 0 }}
        >
            <div className={classes.progress}>
                <LinearProgress />
            </div>
        </Fade>
    )

    return (
        <Card
            className={classes.root}
            raised={raised}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >

            <ExpansionPanel expanded={selected}>
                <ExpansionPanelSummary
                    className={classes.summary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id={sysparam.id}
                    onClick={handleExpansionPanelClick}
                >
                    <div className={classes.summaryContent}>
                        <div className={classes.column}>
                            <Typography align="left" className={classes.heading}>{sysparam.name}</Typography>
                        </div>
                        <div className={classes.column}>
                            <Typography align="left" className={classes.secondaryHeading}>{selected ? '' : sysparam.description}</Typography>
                        </div>
                        {!selected && renderProgress()}
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    {renderSysparamValues(sysparam)}
                    <div className={clsx(classes.column, classes.helper)}>
                        <Typography align="left" variant="caption">
                            {sysparam.description}
                            <br />
                            <a href="#sub-labels-and-columns" className={classes.link}>
                                More info?
                            </a>
                        </Typography>
                    </div>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button
                        size="small"
                        onClick={handleDeleteItem}
                    >
                        <DeleteIcon color="secondary"/>
                        Delete
                    </Button>
                    <Fade
                        in={!state.updateSysStatus[sysparam.name] || state.updateSysStatus[sysparam.name] === 'failed'}
                        timeout={{ enter: 300, exit: 0 }}
                    >
                        <div>
                            <Button

                                size="small"
                                onClick={handleResetClick}
                                disabled={!isBeenChanged()}
                            >
                                <RotateLeftIcon />
                                Reset
                            </Button>
                            <Button
                                size="small"
                                color="secondary"
                                onClick={handleSaveClick}
                                disabled={!isBeenChanged()}
                            >
                                Save
                    </Button>
                        </div>
                    </Fade>
                    { selected && renderProgress() }
                </ExpansionPanelActions>
            </ExpansionPanel>
        </Card>
    );
}


export default CardSysOverride
