import { combineReducers } from 'redux'

// All reducers
import auth from './reducers/auth'
import dashboard from './reducers/dashboard'
import setting from './reducers/setting'
import filter from './reducers/filter'

const rootReducer = combineReducers({
    auth,
    dashboard,
    setting,
    filter
})


export default rootReducer