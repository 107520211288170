import configApi from '../../config'
import { getAxios } from '../../utils/myAxios'
import createUUID from '../../utils/uuidCreator'
import * as types from '../actionTypes'



export const fetchAllSysparam = () => (dispatch, getState) => {

    dispatch({ type: types.FETCH_ALL_SYSPARAM_START })
    getAxios().get(`${configApi.URL.SETTING.GET_ALL_SYSPARAM}`)
        .then(({ data }) => {
            // TODO get the descriptions for sysparam
            let list = data.data.map(sys => ({ ...sys, id: createUUID()}))

            dispatch({
                type: types.FETCH_ALL_SYSPARAM_SUCCESS,
                payload: list
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.FETCH_ALL_SYSPARAM_FAILURE,
                payload: message
            })
        })
}

// export const fetchAccountSysParamOverride = () => (dispatch) => {
//     dispatch({ type: types.FETCH_SYSPARAM_OVERRIDE_START })
//     getAxios().get(configApi.URL.SETTING.GET_SYSPARAM_OVERRIDE)
//         .then(({ data }) => {
//             dispatch({
//                 type: types.FETCH_SYSPARAM_OVERRIDE_SUCCESS,
//                 payload: data.data
//             })
//         })
//         .catch(({ message }) => {
//             dispatch({
//                 type: types.FETCH_SYSPARAM_OVERRIDE_FAILURE,
//                 payload: message
//             })
//         })
// }

export const updateSysParamOverride = ({ accountId, name, value1, value2, text }) => (dispatch) => {
    console.log('updateSysParamOverride', { accountId, name, value1, value2, text })
    dispatch({ type: types.UPDATE_SYSPARAM_OVERRIDE_START, payload: { name } })

    getAxios().post(configApi.URL.SETTING.GET_SYSPARAM_OVERRIDE, { accountId, name, value1, value2, text })
        .then(({ data }) => {
            dispatch({
                type: types.UPDATE_SYSPARAM_OVERRIDE_SUCCESS,
                payload: { accountId, name, value1, value2, text }
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.UPDATE_SYSPARAM_OVERRIDE_FAILURE,
                payload: { name, message }
            })
        })
}

export const addSysParamOverride = ({ accountId, name, value1, value2, text }) => (dispatch, getState) => {
    dispatch({ type: types.ADD_SYSPARAM_OVERRIDE_START, payload: { name } })
    
    const { accountId } = getState().auth
    console.log('addSysParamOverride', { accountId, name, value1, value2, text })
    getAxios().post(configApi.URL.SETTING.ADD_SYSPARAM_OVERRIDE, { accountId, name, value1, value2, text })
        .then(({ data }) => {
            dispatch({
                type: types.ADD_SYSPARAM_OVERRIDE_SUCCESS,
                payload: { accountId, name, value1, value2, text }
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.ADD_SYSPARAM_OVERRIDE_FAILURE,
                payload: { name, message }
            })
        })
}

export const deleteSysParamOverride = ({ name }) => (dispatch, getState) => {
    console.log('deleteSysParamOverride', { name })
    const { accountId } = getState().auth
    getAxios().delete(`${configApi.URL.SETTING.GET_SYSPARAM_OVERRIDE}?Name=${name}&accountId=${accountId}`)
        .then(({ data }) => {
            dispatch({
                type: types.DELETE_SYSPARAM_OVERRIDE_SUCCESS,
                payload: { name }
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.DELETE_SYSPARAM_OVERRIDE_FAILURE,
                payload: { name, message }
            })
        })
}