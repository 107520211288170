import React, { useState, useEffect } from 'react'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
// actions
import { addSysParamOverride, fetchAllSysparam } from '../../../redux/actions/setting'
// selectors
import { getAllSysparam } from '../../../redux/selectors/setting'
// components
import TransferList from './TransferList'
import PageContainer from '../../PageContainer'
import ListSysparam from './ListSysparam'
import { Button, Fab  } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    fab: {
      margin: theme.spacing(1),
      posiiton: "absolute",
      zIndex: 1000,
      bottom: '16px',
      right: '16px'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));


const AllSysparams = (props) => {

    const classes = useStyles();

    const {
        addSysParamOverride,
        allSysparam,
        fetchAllSysparam
    } = props

    const [sysparamForOverride, setSysparamForOverride] = useState([])

    useEffect(() => {
        if (allSysparam.length === 0)
            fetchAllSysparam()
    })

    function handleAddSysparamOverride() {
        addSysParamOverride(sysparamForOverride[0]) // test with one sysparam
    }

    function onItemClick(sysparm) {
        const tempList = fromJS(sysparamForOverride).toJS()
        tempList.push(sysparm)
        setSysparamForOverride(tempList)
    }

    return (
        <PageContainer>
            {/* <div style={{ position: "static", buttom: "60px", zIndex: "100000" }}>
            </div> */}
            <Button variant="contained" color="secondary" onClick={handleAddSysparamOverride}>Add to sysparam override</Button>
            <ListSysparam onItemClick={onItemClick}/>
            {/* <Fab color="secondary" aria-label="add" className={classes.fab}>
                <AddIcon />
            </Fab> */}
        </PageContainer>
    )
}

const mapStateToProps = (state) => ({
    allSysparam: getAllSysparam(state),
})

const mapDispatchToProps = {
    addSysParamOverride,
    fetchAllSysparam
}

export default connect(mapStateToProps, mapDispatchToProps)(AllSysparams)
