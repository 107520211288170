// primary colors
const BLUE_GREY = '#37474f'
const BLUE_GREY_LIGHT = '#62727b'
const BLUE_GREY_DARK = '#102027'

// secondary colors
const PINK = '#ec407a'
const PINK_LIGHT = '#ff77a9'
const PINK_DARK = '#b4004e'

const ORANGE = '#ff3d00'
const ORANGE_LIGHT = '#ff7539'
const ORANGE_DARK = '#c30000'

const WHITE = '#fff'
const GREY_LIGHT = '#e1f5fe'


export default {
    BLUE_GREY,
    BLUE_GREY_LIGHT,
    BLUE_GREY_DARK,
    PINK,
    PINK_LIGHT,
    PINK_DARK,
    ORANGE,
    ORANGE_DARK,
    ORANGE_LIGHT,
    WHITE,
    GREY_LIGHT
}