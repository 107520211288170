import React, { useState } from 'react'
// component
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'




function ListItemSysparam(props) {

    const { sysparam, onItemClick } = props
    const { name, id, accountId } = sysparam

    const [checked, setChecked] = useState(false)

    const handleItemClick = value => () => {
        if (typeof onItemClick === 'function') {
            onItemClick(value)
        }
        setChecked(prev => !prev)
    }

    return (
        <ListItem role={undefined} dense button onClick={handleItemClick(sysparam)}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={Boolean(accountId) || checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': name }}
                />
            </ListItemIcon>
            <ListItemText id={name} primary={`${name}`} />
        </ListItem>
    );
}


export default ListItemSysparam
