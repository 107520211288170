import * as types from '../actionTypes'
import { pages } from '../../constants'
import { fromJS } from 'immutable'


const initialState = {
    page: pages.HOME,
    appMenu: {
        isOpen: false,
        selectedItem: pages.HOME
    }
}

export const dashboard = (state = initialState, { type, payload }) => {
  	    switch (type) {
            case types.OPEN_APP_MENU: {
                const appMenu = fromJS(state.appMenu).toJS()
                appMenu.isOpen = true
                return { ...state, appMenu }
            }
            case types.CLOSE_APP_MENU: {
                const appMenu = fromJS(state.appMenu).toJS()
                appMenu.isOpen = false
                return { ...state, appMenu }
            }
            case types.CHANGE_PAGE: {
                return { ...state, page: payload}
            }  
  		  default:
  	    	  return state
  	}
}

export default dashboard
